.pixel-filters {
	background: rgba(249, 250, 251, 0.55);
	padding: 0 0.78571429em 0.92857143em;
	/* 11px/14px and 13px/14px – these correspond to paddings used in <th>s below */
}

.pixel-filters .ui.form > .field,
.pixel-filters .ui.form > .fields {
	margin-bottom: 0 !important;
}

@media (min-width: 1024px) {
  .custom-table.pixel-table thead tr > th:first-child {
    min-width: 48vw;
  }

  .custom-table.pixel-table thead tr > th:nth-child(2),
  .custom-table.pixel-table thead tr > th:nth-child(3),
  .custom-table.pixel-table thead tr > th:nth-child(4) {
    max-width: 110px;
  }

  .custom-table.pixel-table thead tr > th:nth-child(5) {
    min-width: 180px;
  }
}
