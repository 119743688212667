.pixel-modal-snippet {
	border: 1px solid rgba(34,36,38,.30);
	border-radius: .28571429rem;
	padding: .67857143em 1em;
	white-space: pre-wrap;
	font-size: .8em;
}

.pixel-modal-example {
	margin-left: 15px;
	font-size: .8em;
}

.pixel-modal-example > label {
	font-weight: bold;
	display: block;
}

.pixel-modal-example > pre {
	white-space: pre-wrap;
	overflow-wrap: break-word;
	margin-top: .5em;
}
