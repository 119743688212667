div.assigned-creatives {
	margin-top: 5px;
	min-height: 150px;
	border: 1px solid #ccc;
	padding: 10px;
	border-radius: 5px;
}

div.assigned-creatives .label {
	background-color: var(--primary-color) !important;
	border-color: var(--primary-color) !important;
	color: #fff !important;
	margin-bottom: 3px;
}

.grid .checkbox > label {
	top: 2px !important;
}
