div.nested-targets ul,
div.search-results-list ul {
	list-style: none;
	padding-left: 25px;
}

.arrow-right {
	width: 0;
	height: 0;
	top: 11px;
	border-top: 5px solid transparent;
	border-bottom: 5px solid transparent;

	border-left: 5px solid black;
	position: absolute;

	cursor: pointer;
	left: 12px;
}

div.ul-container.opened > div.arrow-right {
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;

	border-top: 5px solid black;
	border-bottom: none;
	top: 13px;
	left: 8px;
}

div.nested-targets div.ul-container {
	position: relative;
}

div.nested-targets > div > ul div.ul-container {
	display: none;
}

div.nested-targets div.opened > ul > li > div.ul-container {
	display: block !important;
}



label.no-parents::before {
	display: none;
}

div.nested-targets li > div.field {
	margin: 10px 0 !important;
}

label.target-label {
	display: inline-block !important;
	cursor: pointer;
	top: 4px !important;
	white-space: nowrap;
	padding-left: 5px;
}

/* When an item (or its parent's sibling) has both a checkbox and an arrow, compensate for it with extra padding: */

.ul-container.has-children.checkable .custom-checkbox label::before,
.ul-container.special-case .ul-container .custom-checkbox label::before {
	top: 3px;
	left: 0;
	position: relative;
	margin-right: 7px;
}

.ul-container.has-children.checkable .custom-checkbox input[type="checkbox"]:checked + label::after,
.ul-container.has-children.checkable .custom-checkbox input[type="checkbox"]:indeterminate + label::after,
.ul-container.special-case .ul-container .custom-checkbox input[type="checkbox"]:checked + label::after,
.ul-container.special-case .ul-container .custom-checkbox input[type="checkbox"]:indeterminate + label::after {
	left: 9px;
	top: 8px;
}

button.btn-clear {
	padding: 0.3em 1.3em;
	bottom: 2px;
	position: relative;
	margin-left: 5px;
}

.info.icon.tiny {
	bottom: 3px;
	position: relative;
	left: 5px;
}

.included_pixel, .included_audience {
	min-height: 150px;
	padding-bottom: 20px;
	position: relative;
	border-bottom: 1px solid #ccc;
}

.included_pixel > .container, .included_audience > .container {
	padding: 10px;
	min-height: 75px;
}

#included_id .container.droppable-active, .assigned-creatives.container.droppable-active {
	background-color: #eee;
	outline: 2px dotted #ccc;
}

#included_id .container.droppable-over, .assigned-creatives.container.droppable-over {
	background-color: rgba(187, 208, 233, 0.5) !important;
}

.included_pixel > div:first-child, .included_audience > div:first-child {
	padding: 10px;
	background-color: rgb(187, 208, 233);
	border-bottom: 1px solid #ccc;
}

.included_audience > div:first-child {
	background-color: #ccc;
}

.included_pixel span, .included_audience span {
	float: right;
}

.included_pixel > div:last-child, .included_audience > div:last-child {
	position: absolute;
	bottom: 5px;
	left: 0;
	right: 0;
}

