.miller-picker {
	display: inline-flex;
	flex-direction: row;

	/* Apply the shadow part to cards as a whole... */
	filter: drop-shadow(0 1px 3px #d4d4d5);
}

.miller-picker.disabled {
	pointer-events: none;
	opacity: .45;
}

.ui.card.miller-picker-column {
	margin-top: 0;
	margin-bottom: 0;

	/* ...while keeping the border “shadow” for each card: */
	box-shadow: 0 0 0 1px #d4d4d5;
}

.ui.form .field.error .ui.card.miller-picker-column {
	background: #fff6f6;
	color: #9f3a38;
}

.ui.card.miller-picker-column > .content.header-content {
	flex: 0;
	padding-inline: .5em;
}

.ui.card.miller-picker-column > .content > .header:not(.ui) {
	margin-top: 0;
	line-height: 1;
	display: flex;
	align-items: center;
	gap: .5rem;
	color: inherit;
}

.ui.card.miller-picker-column > .content.miller-picker-search {
	padding: 0;
	padding-left: .5em;
}

.ui.card.miller-picker-column > .content.miller-picker-search input {
	padding-block: .75em !important;
	padding-right: .5em;
}

.ui.card.miller-picker-column > .miller-picker-list {
	height: calc(8 * 2.22em); /* 8 rows */
	padding: 0;
	overflow-x: auto;
	color: inherit;
}

.ui.card.miller-picker-column .ui.list > .item {
	padding: .5em .5em;
	display: flex;
	align-items: center;
	gap: .5em;
	color: inherit;
}

.ui.card.miller-picker-column:not(:first-child),
.ui.card.miller-picker-column:not(:first-child) > * {
	border-top-left-radius: 0 !important;
	border-bottom-left-radius: 0 !important;
}

.ui.card.miller-picker-column:not(:last-child),
.ui.card.miller-picker-column:not(:last-child) > * {
	border-top-right-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
}
