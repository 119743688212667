:root {
          --logo-url: url(./assets/client/flipkart/logo.png);
          --logo-height: 150px;
          --background-url: url(./assets/client/flipkart/background.jpg);
          --logo-small-url: url(./assets/client/flipkart/logo_small.png);

          --primary-color: rgb(226, 197, 19);
          --header-menu-width: 100px;
          --header-menu-background: #0574d8;

          --header-menu-background-active: rgb(22, 66, 120);
          --header-menu-background-hover: rgba(22, 66, 120, 0.5);
          --login-form-text-color: #FFF;
          --login-form-link-color: #FFF;
          --login-form-height: 450px;

          --menu-icon-padding-top: 10px;
      }

